import { Container, LogoutButton, MenuItem, MenuSection } from './ui'
import type { DropdownUserMenuProps } from './useDropdownMenuLinks'
import useDropdownMenuLinks from './useDropdownMenuLinks'

const DropdownUserMenu = (props: DropdownUserMenuProps) => {
  const links = useDropdownMenuLinks(props)
  return (
    <Container>
      {links.map((section) => (
        <MenuSection key={section.key}>
          {section.items.map((item) => (
            <MenuItem key={item.key}>
              {item.type === 'link' ? (
                <a href={item.href}>{item.label}</a>
              ) : (
                <LogoutButton type="button" onClick={item.handleClick}>
                  {item.label}
                </LogoutButton>
              )}
            </MenuItem>
          ))}
        </MenuSection>
      ))}
    </Container>
  )
}

export default DropdownUserMenu
