'use client'

import {
  MobileNavSectionTitle,
  MobileNavSection,
  MobileNavLinks,
  MobileNavLink,
} from './ui'
import navData from '../navData.json'

export default function MobileNavigation() {
  return (
    <MobileNavLinks id="MobileNavLinks">
      <MobileNavSectionTitle>For Buyers</MobileNavSectionTitle>
      <MobileNavSection>
        {navData.forBuyer.map((item) => (
          <MobileNavLink key={item.href} href={item.href}>
            {item.name}
          </MobileNavLink>
        ))}
      </MobileNavSection>
      <MobileNavSectionTitle>For Sellers</MobileNavSectionTitle>
      <MobileNavSection>
        {navData.forSeller.map((item) => (
          <MobileNavLink key={item.href} href={item.href}>
            {item.name}
          </MobileNavLink>
        ))}
      </MobileNavSection>
      <MobileNavSectionTitle>Support</MobileNavSectionTitle>
      <MobileNavSection>
        {navData.support.map((item) => (
          <MobileNavLink key={item.href} href={item.href}>
            {item.name}
          </MobileNavLink>
        ))}
      </MobileNavSection>
    </MobileNavLinks>
  )
}
