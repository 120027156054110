import { interpUri } from '@b-stock/bstock-react'

export const getOrdersHref = (accountId: string) =>
  interpUri`/buy/user/${accountId}/orders`

export const getNotificationPath = (personId: string) =>
  interpUri`/acct/settings/${encodeURIComponent(personId)}/notification-preferences`

export const getPersonalSettingsPath = (personId: string) =>
  interpUri`/acct/settings/${personId}/personal`

export const getMyBidsPath = (accountId: string) =>
  interpUri`/buy/user/${accountId}/bids`

export const getDisputesPath = (accountId: string) =>
  interpUri`/buy/user/${accountId}/disputes`
