interface CategoryItem {
  name: string
  href: string
}

interface CategoryHeader {
  title: string
}

interface CategoryFooter {
  text: string
  href: string
}

export interface AllCategoriesDataProps {
  header: CategoryHeader
  items: CategoryItem[]
  footer: CategoryFooter
}

const allCategoriesData: AllCategoriesDataProps = {
  header: {
    title: 'All Categories',
  },
  items: [
    {
      name: 'Apparel, Shoes & Accessories',
      href: 'https://bstock.com/auctions/apparel/',
    },
    {
      name: 'Appliances',
      href: 'https://bstock.com/auctions/appliances/',
    },
    {
      name: 'Automotive Supplies',
      href: 'https://bstock.com/auctions/automotive-supplies/',
    },
    {
      name: 'Books, Movies & Music',
      href: 'https://bstock.com/auctions/books/',
    },
    {
      name: 'Building & Industrial',
      href: 'https://bstock.com/auctions/industrial/',
    },
    {
      name: 'Cell Phones',
      href: 'https://bstock.com/auctions/cell-phones/',
    },
    {
      name: 'Consumer Electronics',
      href: 'https://bstock.com/auctions/consumer-electronics/',
    },
    {
      name: 'Furniture',
      href: 'https://bstock.com/auctions/furniture/',
    },
    {
      name: 'Groceries',
      href: 'https://bstock.com/auctions/grocery/',
    },
    {
      name: 'Health & Beauty',
      href: 'https://bstock.com/auctions/health-beauty-auction/',
    },
    {
      name: 'Home & Garden',
      href: 'https://bstock.com/auctions/home-garden/',
    },

    {
      name: 'Jewelry & Watches',
      href: 'https://bstock.com/auctions/jewelry/',
    },
    {
      name: 'Mixed Lots',
      href: 'https://bstock.com/auctions/mixed-lots/',
    },
    {
      name: 'Office Supplies & Equipment',
      href: 'https://bstock.com/auctions/office-supplies/',
    },
    {
      name: 'Sports and Outdoors',
      href: 'https://bstock.com/auctions/sports-and-outdoors/',
    },
    {
      name: 'Toys, Kids & Baby',
      href: 'https://bstock.com/auctions/toys/',
    },
  ],
  footer: {
    text: 'Shop All Categories',
    href: 'https://bstock.com/all-auctions/',
  },
}

export default allCategoriesData
