'use client'

import { styled } from 'styled-components'

import { designColors } from '@b-stock/bstock-react/theme'

import HeaderAuctionNavigation from './headerAuctionNavigation/HeaderAuctionNavigation'
import HeaderPrimaryNavigation from './headerPrimaryNavigation/HeaderPrimaryNavigation'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
  border-bottom: 1px solid ${designColors.neutral.mediumGray};
`

export default function Header() {
  return (
    <HeaderContainer>
      <HeaderPrimaryNavigation />
      <HeaderAuctionNavigation />
    </HeaderContainer>
  )
}
