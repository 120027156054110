import { stringify as qsEncode } from 'query-string'

const redirectToAuth = () => {
  const qs =
    qsEncode({
      redirectAfterLogin: window.location.pathname,
    }) || ''

  window.location.assign(`/acct/signin?${qs}`)
}

const redirectSignUp = () => {
  const qs =
    qsEncode({
      redirectAfterSignup: window.location.pathname,
    }) || ''

  window.location.assign(`/acct/signup?${qs}`)
}

export { redirectToAuth, redirectSignUp }
