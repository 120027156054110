'use client'

import { styled } from 'styled-components'

import { Button } from '@b-stock/bstock-react'
import {
  breakpoints,
  designColors,
  typography,
} from '@b-stock/bstock-react/theme'

export const UserActionsWrapper = styled.div`
  grid-area: userActions;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media ${breakpoints.max.medium} {
    display: none;
  }
`
export const MobileUserActionsWrapper = styled(UserActionsWrapper)`
  grid-area: mobileUserActions;
  display: none;

  @media ${breakpoints.max.medium} {
    display: flex;
  }
`
export const RegisterButton = styled(Button)`
  height: 2.375rem;
  ${typography.body4};
  background: ${designColors.primary.brightBlue};
  font-weight: bold;
  border-radius: 0.3125rem;
  padding: 0 0.9375rem;
  transition: all 0.3s;
`
export const SignInButton = styled(Button)`
  height: 2.375rem;
  ${typography.body4};
  color: ${designColors.primary.brightBlue};
  border-color: ${designColors.primary.brightBlue};
  font-weight: bold;
  border-radius: 0.3125rem;
  padding: 0 0.9375rem;
  transition: all 0.3s;
`

export const ActionsContainer = styled.div`
  align-items: center;
  border-radius: 0.25rem;
  display: flex;
  gap: 0.125rem;
  padding: 0.375rem 0.5rem 0.375rem 0.375rem;
`
