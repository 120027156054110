// TODO: This SlideOutMenu component used for the mobile user menu.
// It should be used in other places that require a slide out menu.
// It should also be moved to the bstock-react package.

import React, { useState } from 'react'

import { styled } from 'styled-components'

import { designColors } from '@b-stock/bstock-react/theme'

const SlideOutMenuOverlay = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
`

const SlideOutMenuContent = styled.div<{
  $isOpen: boolean
  $side: 'left' | 'right'
}>`
  background-color: ${designColors.neutral.white};
  height: 100%;
  padding: 1.25rem;
  border: 0.0625rem solid ${designColors.neutral.mediumGray};
  position: fixed;
  top: 0;
  ${({ $side, $isOpen }) =>
    $side === 'left'
      ? `left: ${$isOpen ? '0' : '-100%'};`
      : `right: ${$isOpen ? '0' : '-100%'};`}
  transform: translateX(${({ $isOpen, $side }) =>
    $isOpen ? '0' : $side === 'left' ? '-100%' : '100%'});
  transition: transform 0.3s ease-in-out;
`

const CloseButton = styled.span`
  color: ${designColors.neutral.darkGray};
  float: right;
  font-size: 1.75rem;
  font-weight: bold;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${designColors.neutral.black};
    text-decoration: none;
  }
`

interface SlideOutMenuProps {
  children: React.ReactNode
  width?: string
  openButtonComponent?: React.ReactNode
  side?: 'left' | 'right'
}

const SlideOutMenu = ({
  children,
  width = '30%',
  openButtonComponent,
  side = 'right',
}: SlideOutMenuProps) => {
  const [isSlideOutMenuOpen, setIsSlideOutMenuOpen] = useState(false)

  const handleOpenSlideOutMenu = () => {
    setIsSlideOutMenuOpen(true)
  }

  const handleCloseSlideOutMenu = () => {
    setIsSlideOutMenuOpen(false)
  }

  return (
    <>
      {React.cloneElement(openButtonComponent as React.ReactElement, {
        onClick: handleOpenSlideOutMenu,
      })}

      <SlideOutMenuOverlay
        $isOpen={isSlideOutMenuOpen}
        onClick={handleCloseSlideOutMenu}
      >
        <SlideOutMenuContent
          $isOpen={isSlideOutMenuOpen}
          $side={side}
          onClick={(e) => e.stopPropagation()}
          style={{ width }}
        >
          <CloseButton onClick={handleCloseSlideOutMenu}>&times;</CloseButton>
          {children}
        </SlideOutMenuContent>
      </SlideOutMenuOverlay>
    </>
  )
}

export default SlideOutMenu
