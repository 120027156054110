'use client'

import { useRef } from 'react'

import { faBars } from '@fortawesome/pro-regular-svg-icons'

import DropdownNavigation from './dropdownNavigation/DropdownNavigation'
import MobileNavigation from './mobileNavigation/MobileNavigation'
import SearchWidget from './SearchWidget'
import {
  HeaderDiv,
  NavBrand,
  BstockLogoImg,
  Nav,
  StyledFAIcon,
  MobileNavTrigger,
} from './ui'
import MobileUserActions from './userActions/MobileUserActions'
import UserActions from './userActions/UserActions'
import SlideOutMenu from '../common/slideOutMenu/SlideOutMenu'
export default function HeaderPrimaryNavigation() {
  const mobileNavTriggerRef = useRef<HTMLButtonElement>(null)

  const MobileNavTriggerButton = (
    <MobileNavTrigger id="navTrigger" ref={mobileNavTriggerRef}>
      <StyledFAIcon id="openIcon" icon={faBars} $size="1.375rem" />
    </MobileNavTrigger>
  )

  return (
    <HeaderDiv
      id="headerDiv"
      data-gtm-event-position="header"
      data-gtm-element-type="header item"
    >
      <SlideOutMenu
        openButtonComponent={MobileNavTriggerButton}
        width="80%"
        side="left"
      >
        <MobileNavigation />
      </SlideOutMenu>

      <NavBrand
        id="navBrand"
        href="/"
        data-gtm-element-type="header logo"
        data-gtm-element-text="Logo"
      >
        <BstockLogoImg
          id="bstockLogoImg"
          src="/home-portal/images/bstock_logo.svg"
          alt="B-Stock Solutions, LLC."
          width={200}
          height={50}
        />
      </NavBrand>

      <SearchWidget />

      <Nav id="nav">
        <DropdownNavigation />
      </Nav>
      <UserActions />
      <MobileUserActions />
    </HeaderDiv>
  )
}
