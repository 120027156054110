import { styled } from 'styled-components'

import {
  breakpoints,
  designColors,
  typography,
} from '@b-stock/bstock-react/theme'

export const Container = styled.nav`
  margin-top: 3rem;
  background: ${designColors.neutral.white};
  width: 13.75rem;
  @media (${breakpoints.max.small}) {
    width: 100%;
  }
`

export const LogoutButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`

export const MenuSection = styled.ul`
  padding: 0.5rem 0;
  list-style: none;
  border-top: 0.0625rem solid ${designColors.neutral.mediumGray};
  margin: 0;
  &:first-child {
    border-top: none;
  }
`

export const MenuItem = styled.li`
  display: flex;
  margin: 0;
  padding: 0;
  a,
  button {
    ${typography.body3}
    color: ${designColors.neutral.black};
    text-decoration: none;
    text-align: left;
    flex-grow: 1;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: ${designColors.neutral.lightGray};
    }
    &:active {
      background-color: ${designColors.primary.hoverOverlay};
      color: ${designColors.neutral.black};
    }
  }
`
