// TODO: This component is a refactor of the UserMenu component in bstock-react specific to Home Portal inorder to use SlideOutMenu instead of PopOver.
// It should be moved back to bstock-react for Home Portal.

import { useRef } from 'react'

import { Avatar, PortalName } from '@b-stock/bstock-react'

import DropdownUserMenu from './DropdownUserMenu/DropdownUserMenu'
import type { DropdownUserMenuProps } from './DropdownUserMenu/useDropdownMenuLinks'
import UserMenuStyledButton from './UserMenuStyledButton'
import SlideOutMenu from '../../../common/slideOutMenu/SlideOutMenu'

export type UserMenuProps = DropdownUserMenuProps & {
  firstName?: string
  lastName?: string
  showMenuIcon?: boolean
}

const UserMenu = ({
  firstName,
  lastName,
  onLogout,
  accountId,
  personId,
  portalName,
  baseUrl,
}: UserMenuProps) => {
  const userMenuButtonRef = useRef<HTMLButtonElement>(null)

  const isLegacyStyle =
    portalName !== PortalName.SELLER && portalName !== PortalName.CS

  const UserMenuButton = (
    <UserMenuStyledButton ref={userMenuButtonRef} $legacyStyle={isLegacyStyle}>
      {firstName && lastName ? (
        <Avatar size="medium" firstName={firstName} lastName={lastName} />
      ) : (
        <Avatar size="medium" />
      )}
    </UserMenuStyledButton>
  )

  return (
    <SlideOutMenu openButtonComponent={UserMenuButton} width="280px">
      <DropdownUserMenu
        onLogout={onLogout}
        accountId={accountId}
        personId={personId}
        baseUrl={baseUrl}
        portalName={portalName}
      />
    </SlideOutMenu>
  )
}

export default UserMenu
