import { styled } from 'styled-components'

import { designColors, typography } from '@b-stock/bstock-react/theme'

type LegacyStyleProps = {
  $legacyStyle?: boolean
}

const UserMenuStyledButton = styled.button.attrs({
  type: 'button',
})<LegacyStyleProps>`
  ${({ $legacyStyle }) =>
    $legacyStyle ? `font-size: 1.25rem;` : typography.subtitle1}
  display: flex;
  gap: ${({ $legacyStyle }) => ($legacyStyle ? `0.5rem` : `0.25rem`)};
  align-items: center;
  background: none;
  padding: ${({ $legacyStyle }) =>
    $legacyStyle
      ? `0.3125rem 0.3125rem 0.3125rem 0.8125rem`
      : `0.25rem 0.75rem 0.25rem 0.25rem`};
  border-radius: ${({ $legacyStyle }) =>
    $legacyStyle ? `0.3125rem` : `0.25rem`};
  margin: 0;
  border: none;
  color: ${designColors.primary.default};
  cursor: pointer;
  &:hover {
    color: ${designColors.primary.brightBlue};
    background-color: ${designColors.primary.hoverOverlay};
  }
  &:active {
    color: ${designColors.primary.brightBlue};
    background-color: ${designColors.primary.onClickInverse};
  }
`

export default UserMenuStyledButton
