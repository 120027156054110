/* eslint-disable @typescript-eslint/no-unnecessary-condition, @typescript-eslint/no-unused-vars */

import { useEffect, useState } from 'react'

import {
  useAuthState,
  useLogout,
  useAccessTokenRoles,
} from '@b-stock/bstock-next'
import { UserMenu, PortalName } from '@b-stock/bstock-react'

import { redirectSignUp, redirectToAuth } from '@helpers/auth'
import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

import {
  ActionsContainer,
  UserActionsWrapper,
  RegisterButton,
  SignInButton,
} from './ui'

export default function UserActions() {
  const { trackButtonClicked } = useAnalytics()
  const authState = useAuthState()
  const accountRoles = useAccessTokenRoles()
  const logout = useLogout()

  // TEMPORARILY HIDE LOGGED IN USER ACTIONS
  // const isLoggedIn = !!authState?.person
  const isLoggedIn = false

  const baseUrl = 'https://bstock.com/'
  const registerHref = '/acct/signup'
  const signInHref = '/acct/signin'
  const person = authState?.person
  const portalName =
    accountRoles?.buyer || accountRoles?.service
      ? PortalName.BUYER
      : PortalName.SELLER
  const accountId = person?.accountId

  const handleSignIn = () => {
    redirectToAuth()
    // TODO: Make the entity_type dynamic based on the signed-in user's role
    trackButtonClicked('homepage', 'sign_in', 'home_portal', 'buyer', {
      url: document.URL,
      referrer: document.referrer,
      cta_url: signInHref,
    })
  }

  const handleClickRegister = () => {
    redirectSignUp()
    // TODO: Make the entity_type dynamic based on the signed-in user's role
    trackButtonClicked('homepage', 'register_now', 'home_portal', 'buyer', {
      url: document.URL,
      referrer: document.referrer,
      cta_url: registerHref,
    })
  }

  return (
    <UserActionsWrapper>
      {!isLoggedIn ? (
        <>
          <RegisterButton
            onClick={handleClickRegister}
            size="medium"
            appearance="primary"
          >
            Register Now
          </RegisterButton>
          {/* TEMPORARILY HIDE SIGN IN BUTTON
          <SignInButton
            onClick={handleSignIn}
            size="medium"
            appearance="secondary"
          >
            Sign In
          </SignInButton>
          */}
        </>
      ) : (
        <ActionsContainer>
          <UserMenu
            baseUrl={baseUrl}
            accountId={accountId}
            personId={person?._id}
            firstName={person?.firstName}
            lastName={person?.lastName}
            portalName={portalName}
            onLogout={logout}
            showMenuIcon={false}
          />
        </ActionsContainer>
      )}
    </UserActionsWrapper>
  )
}
