import { useMemo } from 'react'

import { interpUri, PortalName } from '@b-stock/bstock-react'

import {
  getNotificationPath,
  getOrdersHref,
  getPersonalSettingsPath,
  getMyBidsPath,
  getDisputesPath,
} from '../helpers'

type AuthenticatedUserMenuProps = {
  onLogout: () => void
  accountId: string
  personId: string
  portalName: PortalName
  baseUrl?: string
}

export type DropdownUserMenuProps = Omit<
  AuthenticatedUserMenuProps,
  'personId' | 'accountId'
> & {
  personId?: AuthenticatedUserMenuProps['personId']
  accountId?: AuthenticatedUserMenuProps['accountId']
}

type MenuItemButton = {
  key: string
  type: 'button'
  handleClick: () => void
  label: React.ReactNode
}

type MenuItemLink = {
  key: string
  type: 'link'
  href: string
  label: React.ReactNode
}

type MenuLinkSection = {
  key: string
  items: (MenuItemButton | MenuItemLink)[]
}
type MenuLinks = MenuLinkSection[]

const getUnauthenticatedMenuLinks = (): MenuLinks => {
  return [
    {
      key: 'create-account',
      items: [
        {
          key: 'create-account',
          type: 'button',
          handleClick: () => {
            window.location.assign(
              interpUri`/acct/signup?redirectAfterSignup=${window.location.pathname}`
            )
          },
          label: 'Create an account',
        },
      ],
    },
    {
      key: 'sign-in',
      items: [
        {
          key: 'sign-in',
          type: 'button',
          handleClick: () => {
            window.location.assign(
              interpUri`/acct/signin?redirectAfterLogin=${window.location.pathname}`
            )
          },
          label: 'Sign in',
        },
      ],
    },
  ]
}

const getAuthenticatedMenuLinks = ({
  onLogout,
  accountId,
  personId,
  portalName,
}: AuthenticatedUserMenuProps): MenuLinks => {
  const sections: MenuLinks = []
  if (portalName !== PortalName.SELLER && portalName !== PortalName.CS) {
    sections.push({
      key: 'my-account',
      items: [
        {
          key: 'my-account',
          type: 'link',
          href: '/acct/settings',
          label: 'My Account',
        },
      ],
    })
    sections.push({
      key: 'settings',
      items: [
        {
          key: 'personal-settings',
          type: 'link',
          href: getPersonalSettingsPath(personId),
          label: 'Personal Settings',
        },
        {
          key: 'business-profile',
          type: 'link',
          href: '/acct/settings/business-profile',
          label: 'Business Profile',
        },
        {
          key: 'notification-preferences',
          type: 'link',
          href: getNotificationPath(personId),
          label: 'Notification Preferences',
        },
      ],
    })
    sections.push({
      key: 'buyer-records',
      items: [
        {
          key: 'bids',
          type: 'link',
          href: getMyBidsPath(accountId),
          label: 'Bids',
        },
        {
          key: 'orders',
          type: 'link',
          href: getOrdersHref(accountId),
          label: 'Orders',
        },
        {
          key: 'disputes',
          type: 'link',
          href: getDisputesPath(accountId),
          label: 'Disputes',
        },
      ],
    })
  }
  sections.push({
    key: 'sign-out',
    items: [
      {
        key: 'sign-out',
        type: 'button',
        handleClick: onLogout,
        label: 'Sign out',
      },
    ],
  })

  return sections
}

const useDropdownMenuLinks = ({
  onLogout,
  accountId,
  personId,
  portalName,
  baseUrl,
}: DropdownUserMenuProps): MenuLinks => {
  return useMemo(() => {
    return personId && accountId
      ? getAuthenticatedMenuLinks({
          onLogout,
          accountId,
          personId,
          portalName,
          baseUrl,
        })
      : getUnauthenticatedMenuLinks()
  }, [onLogout, accountId, personId, portalName, baseUrl])
}

export default useDropdownMenuLinks
