interface SellersItem {
  name: string
  href: string
  ariaLabel: string
}

interface SellersHeader {
  title: string
}

export interface SellersDataProps {
  topSellersHeader: SellersHeader
  topSellers: SellersItem[]
  allSellersHeader: SellersHeader
  allSellers: SellersItem[]
}

const sellersData: SellersDataProps = {
  topSellersHeader: {
    title: 'Top Sellers',
  },
  topSellers: [
    {
      name: 'Amazon',
      href: 'https://bstock.com/amazon/',
      ariaLabel: 'Amazon Liquidation Auctions',
    },
    {
      name: 'Best Buy',
      href: 'https://bstock.com/bestbuy/',
      ariaLabel: 'Best Buy Liquidation Auctions',
    },
    {
      name: 'Costco Wholesale',
      href: 'https://bstock.com/costco/',
      ariaLabel: 'Costco Wholesale Liquidation Auctions',
    },
    {
      name: 'Mobile Carrier',
      href: 'https://bstock.com/mobilecarrier/',
      ariaLabel: 'Mobile Carrier Liquidations',
    },
    {
      name: 'Samsung Mobile',
      href: 'https://bstock.com/samsung/',
      ariaLabel: 'Samsung Mobile Auctions',
    },
    {
      name: 'Superior Wireless',
      href: 'https://bstock.com/superior/',
      ariaLabel: 'Superior Wireless Auctions',
    },
    {
      name: 'Target',
      href: 'https://bstock.com/target/',
      ariaLabel: 'Target Auctions Liquidation',
    },
    {
      name: 'The Home Depot',
      href: 'https://bstock.com/homedepot/',
      ariaLabel: 'The Home Depot Liquidation Auctions',
    },
    {
      name: 'Walmart',
      href: 'https://liquidations.walmart.com/',
      ariaLabel: 'Walmart Liquidation Auctions',
    },
    {
      name: 'Wireless Network',
      href: 'https://bstock.com/wirelessnetwork/',
      ariaLabel: 'Wireless Network Marketplace',
    },
  ],
  allSellersHeader: {
    title: 'All Sellers',
  },
  allSellers: [
    {
      name: 'Ace Hardware',
      href: 'https://bstock.com/acehardware/',
      ariaLabel: 'Ace Hardware Liquidation Auctions',
    },
    {
      name: 'Advance Auto',
      href: 'https://bstock.com/advanceauto/',
      ariaLabel: 'Advance Auto Parts',
    },
    {
      name: 'a.k.a Brands',
      href: 'https://bstock.com/akabrands/',
      ariaLabel: 'a.k.a. Brands Liquidation Auctions',
    },
    {
      name: 'Almo',
      href: 'https://bstock.com/almo/',
      ariaLabel: 'Almo Liquidation Auctions',
    },
    {
      name: 'Alphabroder',
      href: 'https://bstock.com/buy/sellers/details/alphabroder',
      ariaLabel: 'Alphabroder Liquidation Auctions',
    },
    {
      name: 'Amazon',
      href: 'https://bstock.com/amazon/',
      ariaLabel: 'Amazon Liquidation Auctions',
    },
    {
      name: 'Amazon Europe',
      href: 'https://bstock.com/amazoneu/',
      ariaLabel: 'Amazon Liquidation Auctions EU',
    },
    {
      name: 'Ashley Homestore',
      href: 'https://bstock.com/ashley/',
      ariaLabel: 'Ashley Homestore Liquidation Auctions',
    },
    {
      name: 'B-Stock Supply',
      href: 'https://bstock.com/supply/',
      ariaLabel: 'B-Stock Supply',
    },
    {
      name: 'B-Stock Supply Europe',
      href: 'https://bstock.com/supply-europe/',
      ariaLabel: 'B-Stock Supply EU',
    },
    {
      name: 'Bayer',
      href: 'https://bstock.com/bayer/',
      ariaLabel: 'Bayer Liquidation Auctions',
    },
    {
      name: 'Best Buy',
      href: 'https://bstock.com/bestbuy/',
      ariaLabel: 'Best Buy Liquidation Auctions',
    },
    {
      name: 'Beyond Brands',
      href: 'https://bstock.com/buy/sellers/details/beyond',
      ariaLabel: 'Beyond Brands',
    },
    {
      name: 'Big 5 Sporting Goods',
      href: 'https://bstock.com/big5sportinggoods/',
      ariaLabel: 'Big 5 Sporting Goods',
    },
    {
      name: "BJ's",
      href: 'https://bstock.com/buy/sellers/details/bjs',
      ariaLabel: "BJ's Liquidation Auctions",
    },
    {
      name: 'Certideal',
      href: 'https://bstock.com/certideal/',
      ariaLabel: 'Certideal Liquidation Auctions',
    },
    {
      name: 'Contemporary Fashion Auctions',
      href: 'https://bstock.com/contemporaryfashion/',
      ariaLabel: 'Contemporary Fashion Auctions',
    },
    {
      name: 'Costco Appliances',
      href: 'https://bstock.com/buy/sellers/details/costcoappliances',
      ariaLabel: 'Costco Appliances and Home Liquidation Auctions',
    },
    {
      name: 'Costco Canada',
      href: 'https://bstock.com/costcocanada/',
      ariaLabel: 'Costco Liquidation Auctions Canada',
    },
    {
      name: 'Costco Wholesale',
      href: 'https://bstock.com/costco/',
      ariaLabel: 'Costco Wholesale Liquidation Auctions',
    },
    {
      name: 'Crescent Electric Supply Company',
      href: 'https://bstock.com/buy/sellers/details/crescentelectricsupply',
      ariaLabel: 'Crescent Electric Supply Company',
    },
    {
      name: "Dick's Sporting Goods",
      href: 'https://bstock.com/dickssportinggoods/',
      ariaLabel: "Dick's Sporting Goods Liquidations",
    },
    {
      name: 'Disney Europe',
      href: 'https://bstock.com/disneyuk/',
      ariaLabel: 'Disney Liquidation Auctions',
    },
    {
      name: 'Dorel Home',
      href: 'https://bstock.com/buy/sellers/details/dorel-home',
      ariaLabel: 'Dorel Home',
    },
    {
      name: 'EcoATM',
      href: 'https://bstock.com/ecoatm/',
      ariaLabel: 'EcoATM Auctions',
    },
    {
      name: 'Ecolab',
      href: 'https://bstock.com/ecolab/',
      ariaLabel: 'Ecolab Liquidation Auctions',
    },
    {
      name: 'Essendant',
      href: 'https://bstock.com/essendant/',
      ariaLabel: 'Essendant Liquidation Auctions',
    },
    {
      name: 'GameStop',
      href: 'https://bstock.com/gamestop/',
      ariaLabel: 'GameStop Wholesale',
    },
    {
      name: 'GE Appliances Canada',
      href: 'https://bstock.com/geappliancescanada/',
      ariaLabel: 'GE Appliances Canada Liquidation Auctions',
    },
    {
      name: 'GE Appliances',
      href: 'https://bstock.com/geappliances/',
      ariaLabel: 'GE Appliances Liquidation Auctions',
    },
    {
      name: 'Grainger',
      href: 'https://bstock.com/grainger/',
      ariaLabel: 'Grainger Liquidation Auctions',
    },
    {
      name: 'Hanes',
      href: 'https://bstock.com/hanes/',
      ariaLabel: 'Hanes Brands Closeouts',
    },
    {
      name: 'Herman Miller',
      href: 'https://bstock.com/hermanmiller/',
      ariaLabel: 'Herman Miller Liquidation Auctions',
    },
    {
      name: 'HSN',
      href: 'https://bstock.com/hsn/',
      ariaLabel: 'HSN Liquidation',
    },
    {
      name: 'HBC',
      href: 'https://bstock.com/hbc/',
      ariaLabel: "Hudson's Bay Liquidation Auctions",
    },
    {
      name: 'JCPenney',
      href: 'https://bstock.com/jcpenney/',
      ariaLabel: 'JCPenney Liquidation Auctions',
    },
    {
      name: 'Journeys',
      href: 'https://bstock.com/journeysgroup/',
      ariaLabel: 'Journeys Liquidations',
    },
    {
      name: 'Kohler',
      href: 'https://bstock.com/kohler/',
      ariaLabel: 'Kohler Auctions',
    },
    {
      name: "Lands' End",
      href: 'https://bstock.com/landsend/',
      ariaLabel: "Lands' End Liquidation Auctions",
    },
    {
      name: 'Last Chance Auctions',
      href: 'https://bstock.com/lastchanceauctions',
      ariaLabel: 'Last Chance Auctions',
    },
    {
      name: "Lowe's",
      href: 'https://bstock.com/lowes/',
      ariaLabel: "Lowe's Liquidation",
    },
    {
      name: 'Major Sporting Event',
      href: 'https://bstock.com/majorsportingevent/',
      ariaLabel: 'Major Sporting Event',
    },
    {
      name: 'Meijer',
      href: 'https://bstock.com/meijer/',
      ariaLabel: 'Meijer Liquidation Auctions',
    },
    {
      name: 'Midwest Catalog Brands',
      href: 'https://bstock.com/midwestcatalogbrands/',
      ariaLabel: 'Midwest Catalog Brands Liquidation Auctions',
    },
    {
      name: 'Mobile Carrier',
      href: 'https://bstock.com/mobilecarrier/',
      ariaLabel: 'Mobile Carrier Liquidations',
    },
    {
      name: 'Moove',
      href: 'https://bstock.com/buy/sellers/details/moove',
      ariaLabel: 'Moove',
    },
    {
      name: 'NFM',
      href: 'https://bstock.com/nfm/',
      ariaLabel: 'NFM Liquidation Auctions',
    },
    {
      name: 'Petco',
      href: 'https://bstock.com/buy/sellers/details/petco',
      ariaLabel: 'Petco Liquidation Auctions',
    },
    {
      name: 'Prestige',
      href: 'https://bstock.com/prestigebrands/',
      ariaLabel: 'Prestige Consumer Healthcare Liquidation Auctions',
    },
    {
      name: 'QVC',
      href: 'https://bstock.com/qvc/',
      ariaLabel: 'QVC Liquidation Auctions',
    },
    {
      name: 'Rent the Runway',
      href: 'https://bstock.com/buy/sellers/details/renttherunway',
      ariaLabel: 'Rent the Runway Liquidation Auctions',
    },
    {
      name: 'Revlon',
      href: 'https://bstock.com/revlonbrands/',
      ariaLabel: 'Revlon Brands Auctions',
    },
    {
      name: "Sam's Club",
      href: 'https://bstock.com/samsclub/',
      ariaLabel: "Sam's Club Liquidations",
    },
    {
      name: 'Samsung Mobile',
      href: 'https://bstock.com/samsung/',
      ariaLabel: 'Samsung Mobile Auctions',
    },
    {
      name: 'Signature Hardware',
      href: 'https://bstock.com/signaturehardware/',
      ariaLabel: 'Signature Hardware Liquidation Auctions',
    },
    {
      name: 'Spectrum Brands',
      href: 'https://bstock.com/buy/sellers/details/spectrum-brands',
      ariaLabel: 'Spectrum Brands',
    },
    {
      name: 'Squaretrade',
      href: 'https://bstock.com/squaretrade/',
      ariaLabel: 'SquareTrade Auctions',
    },
    {
      name: 'Superior Wireless',
      href: 'https://bstock.com/superior/',
      ariaLabel: 'Superior Wireless Auctions',
    },
    {
      name: 'Target',
      href: 'https://bstock.com/target/',
      ariaLabel: 'Target Auctions Liquidation',
    },
    {
      name: 'The Home Depot',
      href: 'https://bstock.com/homedepot/',
      ariaLabel: 'The Home Depot Liquidation Auctions',
    },
    {
      name: 'Trillion',
      href: 'https://bstock.com/trillion/',
      ariaLabel: 'Trillion Wholesale Auctions',
    },
    {
      name: 'UNFI',
      href: 'https://bstock.com/unfi/',
      ariaLabel: 'UNFI Liquidation Auctions',
    },
    {
      name: 'Unilever',
      href: 'https://bstock.com/unilever/',
      ariaLabel: 'Unilever Liquidation Auctions',
    },
    {
      name: 'Universal Athletic',
      href: 'https://bstock.com/universalathletic/',
      ariaLabel: 'Universal Athletic Liquidation Auctions',
    },
    {
      name: 'Veyer',
      href: 'https://bstock.com/veyer/',
      ariaLabel: 'Veyer Liquidation Auctions',
    },
    {
      name: 'Walgreens',
      href: 'https://bstock.com/walgreens/',
      ariaLabel: 'Walgreens Liquidation Auctions',
    },
    {
      name: 'Walker Edison',
      href: 'https://bstock.com/walkeredison/',
      ariaLabel: 'Walker Edison Liquidation Auctions',
    },
    {
      name: 'Walmart',
      href: 'https://liquidations.walmart.com/',
      ariaLabel: 'Walmart Liquidation Auctions',
    },
    {
      name: 'Wayfair Europe',
      href: 'https://bstock.com/wayfaireu/',
      ariaLabel: 'Wayfair EU Liquidation Auctions',
    },
    {
      name: 'Wayfair',
      href: 'https://bstock.com/wayfair/',
      ariaLabel: 'Wayfair Liquidation Auctions',
    },
    {
      name: 'Whirlpool Second Channel',
      href: 'https://bstock.com/whirlpool/',
      ariaLabel: 'Whirlpool Second Channel Marketplace',
    },
    {
      name: 'Wireless Network',
      href: 'https://bstock.com/wirelessnetwork/',
      ariaLabel: 'Wireless Network Marketplace',
    },
    {
      name: 'Woot',
      href: 'https://bstock.com/woot/',
      ariaLabel: 'Woot Liquidation Auctions',
    },
    {
      name: 'World Market Auctions',
      href: 'https://bstock.com/buy/sellers/details/world-market-auctions',
      ariaLabel: 'World Market Auctions',
    },
    {
      name: 'Winston Water Cooler',
      href: 'https://bstock.com/buy/sellers/details/winston-water-cooler',
      ariaLabel: 'WWC Family of Companies',
    },
  ],
}

export default sellersData
