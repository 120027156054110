'use client'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'

import sellers from '../auctionNavData/sellersData'
import {
  MobileSubMenu,
  MobileTitleRow,
  MobileTitleIcon,
  MobileTitle,
  MobileSubTitle,
  SellersMobileItemList,
  MobileItem,
  ItemLink,
} from '../ui'

interface SellersMobileSubMenuProps {
  onClose: () => void
  onClick?: (event: React.MouseEvent) => void
}
const SellersMobileSubMenu = ({
  onClose,
  onClick,
}: SellersMobileSubMenuProps) => {
  const data = sellers
  const handleCloseSubMenu = () => {
    onClose()
  }
  return (
    <MobileSubMenu onClick={onClick} data-testid="sellers-mobile-submenu">
      <MobileTitleRow>
        <MobileTitleIcon
          icon={faChevronLeft}
          onClick={handleCloseSubMenu}
          data-testid="sellers-mobile-title-icon"
        />
        <MobileTitle>Shop by Seller</MobileTitle>
      </MobileTitleRow>
      <MobileSubTitle>{data.allSellersHeader.title}</MobileSubTitle>
      <SellersMobileItemList>
        {data.allSellers.map((item) => (
          <MobileItem key={item.name}>
            <ItemLink
              href={item.href}
              target="_blank"
              rel="noopener"
              aria-label={`View seller ${item.ariaLabel} in a new tab`}
            >
              {item.name}
            </ItemLink>
          </MobileItem>
        ))}
      </SellersMobileItemList>
    </MobileSubMenu>
  )
}

export default SellersMobileSubMenu
